#footer {
    padding: 55px 0 0 0;
    .inf {
        margin-top: 60px;
        .font-2 {
            display: block;
            margin: 0 0 30px 0;
            &:hover {
                color: #2b2b2b;
            }
        }
    }
    .footer-brand {
        min-height: 200px;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
    .overflow-container {
        padding-top: 50px;
        overflow: hidden;
        h2 {
            position: relative;
            margin: 0;
            padding: 0;
            line-height: normal;
            top: 8px;
        }
    }
    .br-1 {
        max-width: 167px;
    }
    .br-2 {
        max-width: 265px;
    }
    .br-3 {
        max-width: 185px;
    }
    .br-4 {
        max-width: 234px;
    }
    a {
        color: #2b2b2b !important;
    }
    .standart-btn {
        max-width: 350px;
    }
    .standart-btn:hover {
        color: #ffffff !important;
    }
    .copyright-section {
        background-color: #d5d5d5;
        padding-top: 35px;
        padding-bottom: 35px;
        position: relative;
        z-index: 500;
        p {
            margin: 0;
            padding: 0;
            font-family: $thin;
            font-size: 10px;
            color: rgba(43,43,43,.7);
            font-weight: 700;
        }
        &__contacts {
            margin-top: 30px;
        }
    }
}