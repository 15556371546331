// 0 - 320px
@media screen and (min-width: 0px) and (max-width: 320px) {
  .grid-title--big .shadow {
      font-size: 35px;
      top: 6px;
      letter-spacing: 18px;
  }
    #header {
        padding: 0;
        .container {
            width: 90%;
        }
    }
    .main-nav {
        padding: 20px 0;
        ul {
            display: none;
            li {
                display: block;
                margin: 0 0 10px 0;
            }
        }
        &.visible {
            ul {
                display: block;
            }
        }
    }
    .menu-btn {
        display: block;
    }
    .icons-list {
        li {
            margin: 10px;
            a img {
                width: 40px;
            }
        }
    }
    .font-1, .title-1 {
        font-size: 18px;
    }
    .font-2 {
        font-size: 17px;
    }
    .font-5 {
        font-size: 14px;
        line-height: 16px;
    }
    .content-1 {
        font-size: 14px;
    }
    .title-4 {
        margin-top: 20px;
        font-size: 16px;
        letter-spacing: 4px;
        line-height: 30px;
        &__bigger {
            font-size: 30px;
        }
    }
    .overflow-container {
        .title-4 {
            top: 6px;
        }
    }
    .socials-nav {
        li {
            margin: 15px;
            a img {
                width: 40px;
            }
        }
    }
    .artists, .artists-team {
        .artist-item {
            width: calc(100% / 5 - 5px);
            margin-right: 0;
            height: 75px;
            &__hover {
                span.font-1, span.content-1 {
                    font-size: 10px;
                }
            }
        }
        .wide-img {
            text-align: center;
        }
    }
    .popup {
        &-wraper {
            width: 90%;
            top: 40px;
            transform: translate(-50%, 0);
        }
        input, textarea {
            position: relative;
        }
        label, input, textarea {
            font-size: 16px;
        }
        .standart-btn {
            width: 100%;
        }
        form {
            div {
                margin-bottom: 10px;
            }
        }
    }
    .reviews {
        padding-top: 20px;
        padding-bottom: 20px;
        .client-item {
            min-height: 80px;
            img {
                &.cl-1, &.cl-2, &.cl-3, &.cl-4,
                &.cl-5, &.cl-6, &.cl-7, &.cl-8,
                &.cl-9, &.cl-10, &.cl-11, &.cl-12,
                &.cl-13, &.cl-14, &.cl-15, &.cl-16,
                &.cl-17 {
                    max-width: 90%;
                }
                &.cl-12 {
                    max-width: 50%;
                }
            }
        }
        .overflow-container {
            margin-bottom: 20px;
            .title-4 {
                top: 6px;
            }
        }
        .team-item {
            margin-bottom: 20px;
            .avatar-block {
                width: 120px;
                height: 120px;
                background-size: cover;
            }
            img {
                max-width: 50%;
                margin-top: -15px;
            }
            .about-block {
                margin-bottom: 0;
                height: 125px;
            }
        }
        .review {
            top: 10px;
            &:before {
                font-size: 100px;
                line-height: 18px;
                left: -35px;
            }
        }
    }
    .services {
        padding-top: 45px;
        background-size: 100% auto;
        background-position: center 40px;
        .services-block {
            padding-top: 0;
            padding-bottom: 0;
            ul {
                li {
                    width: calc(100% / 4);
                    img {
                        height: 65px;
                    }
                    p {
                        font-size: 14px;
                        letter-spacing: 2px;
                    }
                    p.desc {
                        font-size: 8px;
                        margin-top: 15px;
                        letter-spacing: 1px;
                    }
                }
            }
        }
        .standart-btn {
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 16px;
            padding: 10px;
        }
    }
    .services_no-bg {
        padding-top: 0;
        margin: -25px 0 -50px 0;
        & + .text-block {
            padding-top: 0;
        }   
    }
    .artist-title {
        font-size: 18px;
        margin-top: 10px;
        h3 {
            top: 3px;
            font-size: 16px;
        }
    }
    .artists {
        padding-top: 20px;
        padding-bottom: 20px;
        .overflow-container {
            h2 {
                top: 5px;
            }
        }
    }
    .standart-btn {
        display: inline-block;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 16px;
        padding: 10px;
    }
    .artists-team {
        padding-top: 20px;
        padding-bottom: 20px;
        .review {
            &:before {
                display: none;
            }
        }
        .artist-item {
            width: calc(100% / 2 - 5px);
            height: 150px;
        }
        .overflow-container {
            h2 {
                top: 6px;
            }
        }
    }
    #footer {
        padding-top: 20px;
        
        .inf {
            margin-top: 20px;
            .font-2 {
                margin-bottom: 10px;
            }
            .title-1 {
                margin-bottom: 10px;
            }
        }
        .overflow-container {
            padding-top: 20px;
            h2 {
                top: 6px;
            }
        }
        .br-1, .br-2, .br-3, .br-4 {
            max-width: 100%;
        }
        .footer-brand {
            min-height: 80px;
        }
    }
    #art .artist-title h3 {
        top: 3px;
    }
    section, .mission, .contacts-about {
        padding: 20px 0;
    }
    .contacts-about .content-1, .mission .content-1, .talent .content-1, .text-block .content-1, .why-choose-us .content-1 {
        font-size: 16px;
        margin-top: 20px;
    }
    .services .service-item .bg-container {
        transform: translate(-35%, 0) scale(0.4);
    }
    // #team>div>div:nth-child(3)>div:nth-child(4) {
    //     background-position-y: -32px;
    //     background-size: 118%;
    // }
    body > section.reviews > div > div:nth-child(2) > div:nth-child(3) > div.about-block > div:nth-child(1) > img {
        max-width: 100%;
    }
    .popup-wraper .close-popup {
        right: -10px;
    }
    body > section.reviews > div > div:nth-child(2) > div:nth-child(1) > div.about-block > div:nth-child(1) > img {
        width: 40%;
    }
    .popup textarea {
        height: 28px;
    }
    .contacts-mail-section {
        margin-bottom: 60px;
        .font-2 {
            font-size: 23px;
        }
    }
    .title-4 {
        &.about-title {
            margin-top: 20px;
            margin-bottom: 0;
        }
    }
}

// 321 - 480px
@media screen and (min-width: 321px) and (max-width: 480px) {
  .grid-title--big .shadow {
    font-size: 35px;
    top: 6px;
    letter-spacing: 18px;
  }
    #header {
        padding: 0;
        .container {
            width: 90%;
        }
    }
    .main-nav {
        padding: 20px 0;
        ul {
            display: none;
            li {
                display: block;
                margin: 0 0 10px 0;
            }
        }
        &.visible {
            ul {
                display: block;
            }
        }
    }
    .menu-btn {
        display: block;
    }
    .icons-list {
        li {
            margin: 10px;
            a img {
                width: 40px;
            }
        }
    }
    .font-1, .title-1 {
        font-size: 18px;
    }
    .font-2 {
        font-size: 17px;
    }
    .font-5 {
        font-size: 14px;
        line-height: 16px;
    }
    .content-1 {
        font-size: 14px;
    }
    .title-4 {
        margin-top: 20px;
        font-size: 16px;
        letter-spacing: 4px;
        line-height: 30px;
        &__bigger {
            font-size: 30px;
        }
    }
    .overflow-container {
        .title-4 {
            top: 6px;
        }
    }
    .socials-nav {
        li {
            margin: 15px;
            a img {
                width: 40px;
            }
        }
    }
    .artists, .artists-team {
        .artist-item {
            width: calc(100% / 5 - 5px);
            margin-right: 0;
            height: 104px;
            &__hover {
                span.font-1, span.content-1 {
                    font-size: 10px;
                }
            }
        }
        .wide-img {
            text-align: center;
        }
    }
    .popup {
        &-wraper {
            width: 90%;
            top: 40px;
            transform: translate(-50%, 0);
        }
        input, textarea {
            position: relative;
        }
        label, input, textarea {
            font-size: 16px;
        }
        .standart-btn {
            width: 100%;
            max-width: 100%;
        }
        form {
            div {
                margin-bottom: 10px;
            }
        }
    }
    .reviews {
        padding-top: 20px;
        padding-bottom: 20px;
        .client-item {
            min-height: 100px;
            img {
                &.cl-1, &.cl-2, &.cl-3, &.cl-4,
                &.cl-5, &.cl-6, &.cl-7, &.cl-8,
                &.cl-9, &.cl-10, &.cl-11, &.cl-12,
                &.cl-13, &.cl-14, &.cl-15, &.cl-16,
                &.cl-17 {
                    max-width: 90%;
                }
                &.cl-12 {
                    max-width: 50%;
                }
            }
        }
        .overflow-container {
            margin-bottom: 20px;
            .title-4 {
                top: 6px;
            }
        }
        .team-item {
            margin-bottom: 20px;
            .avatar-block {
                width: 120px;
                height: 120px;
                background-size: cover;
            }
            img {
                max-width: 50%;
                margin-top: -15px;
            }
            .about-block {
                margin-bottom: 0;
                height: 125px;
            }
        }
        .review {
            top: 20px;
            &:before {
                font-size: 100px;
                line-height: 18px;
                left: -35px;
            }
        }
    }
    .services {
        padding-top: 45px;
        padding-top: 45px;
        background-size: 100% auto;
        background-position: center 40px;
        .services-block {
            padding-top: 0;
            padding-bottom: 0;
            ul {
                li {
                    width: calc(100% / 4);
                    img {
                        height: 65px;
                    }
                    p {
                        font-size: 14px;
                        letter-spacing: 2px;
                    }
                    p.desc {
                        font-size: 8px;
                        margin-top: 15px;
                        letter-spacing: 1px;
                    }
                }
            }
        }
        .standart-btn {
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 16px;
            padding: 10px;
        }
    }
    .services_no-bg {
        padding-top: 0;
        margin: -25px 0 -50px 0;
        & + .text-block {
            padding-top: 0;
        }   
    }
    .artist-title {
        font-size: 18px;
        margin-top: 10px;
        h3 {
            font-size: 16px;
            top: 3px;
        }
    }
    .artists {
        padding-top: 20px;
        padding-bottom: 20px;
        .overflow-container {
            h2 {
                top: 5px;
            }
        }
    }
    .standart-btn {
        display: inline-block;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 16px;
        padding: 10px;
    }
    .artists-team {
        padding-top: 20px;
        padding-bottom: 20px;
        .review {
            &:before {
                display: none;
            }
        }
        .artist-item {
            width: calc(100% / 2 - 5px);
            height: 245px;
        }
        .overflow-container {
            h2 {
                top: 6px;
            }
        }
    }
    #footer {
        padding-top: 20px;
        .inf {
            margin-top: 20px;
            .font-2 {
                margin-bottom: 10px;
            }
            .title-1 {
                margin-bottom: 10px;
            }
        }
        .overflow-container {
            padding-top: 20px;
            h2 {
                top: 6px;
            }
        }
        .br-1, .br-2, .br-3, .br-4 {
            max-width: 100%;
        }
        .footer-brand {
            min-height: 80px;
        }
    }
    #art .artist-title h3 {
        top: 3px;
    }
    section, .mission, .contacts-about {
        padding: 20px 0;
    }
    .contacts-about .content-1, .mission .content-1, .talent .content-1, .text-block .content-1, .why-choose-us .content-1 {
        font-size: 16px;
        margin-top: 20px;
    }
    .services .service-item .bg-container {
        transform: translate(-35%, 0) scale(0.4);
    }
    // #team>div>div:nth-child(3)>div:nth-child(4) {
    //     background-size: 129%;
    //     background-position-y: -16px;
    // }
    body > section.reviews > div > div:nth-child(2) > div:nth-child(3) > div.about-block > div:nth-child(1) > img {
        max-width: 60%;
    }
    .popup-wraper .close-popup {
        right: -10px;
    }
    body > section.reviews > div > div:nth-child(2) > div:nth-child(1) > div.about-block > div:nth-child(1) > img {
        width: 40%;
    }
    .popup textarea {
        height: 28px;
    }
    .contacts-mail-section {
        margin-bottom: 60px;
        .font-2 {
            font-size: 23px;
        }
    }
    .title-4 {
        &.about-title {
            margin-top: 20px;
            margin-bottom: 0;
        }
    }
}

// 481 - 768px
@media screen and (min-width: 481px) and (max-width: 768px) {
  .grid-title--big .shadow {
    font-size: 30px;
    top: 8px;
    letter-spacing: 40px;
  }
    #header {
        padding: 0;
        .container {
            width: 90%;
        }
    }
    .main-nav {
        padding: 20px 0;
        ul {
            display: none;
            li {
                display: block;
                margin: 0 0 10px 0;
            }
        }
        &.visible {
            ul {
                display: block;
            }
        }
    }
    .menu-btn {
        display: block;
    }
    .icons-list {
        li {
            margin: 10px;
            a img {
                width: 40px;
            }
        }
    }
    .socials-nav {
        li {
            margin: 15px;
            a img {
                width: 40px;
            }
        }
    }
    .artists, .artists-team {
        .artist-item {
            width: calc(100% / 5 - 5px);
            margin-right: 0;
        }
        .wide-img {
            text-align: center;
        }
    }
    .popup {
        &-wraper {
            width: 90%;
            top: 40px;
            transform: translate(-50%, 0);
        }
        input, textarea {
            position: relative;
        }
        label, input, textarea {
            display: block;
            font-size: 16px;
            max-width: 100%
        }
        .standart-btn {
            width: 100%;
        }
        form {
            div {
                margin-bottom: 10px;
            }
        }
    }
    .reviews {
        padding-top: 40px;
        padding-bottom: 40px;
        .client-item {
            min-height: 100px;
            img {
                &.cl-1, &.cl-2, &.cl-3, &.cl-4,
                &.cl-5, &.cl-6, &.cl-7, &.cl-8,
                &.cl-9, &.cl-10, &.cl-11, &.cl-12,
                &.cl-13, &.cl-14, &.cl-15, &.cl-16,
                &.cl-17 {
                    max-width: 90%;
                }
                &.cl-12 {
                    max-width: 50%;
                }
            }
        }
        .overflow-container {
            margin-bottom: 20px;
        }
        .team-item {
            margin-bottom: 20px;
            .avatar-block {
                width: 180px;
                height: 180px;
                background-size: cover;
            }
            img {
                max-width: 40%;
                margin-top: -23px;
            }
            .about-block {
                margin-bottom: 0;
                height: 180px;
            }
        }
        .review {
            &:before {
                font-size: 100px;
                line-height: 18px;
                left: -35px;
            }
        }
    }
    .services {
        padding-top: 45px;
        .services-block {
            padding-top: 0;
            padding-bottom: 0;
            ul {
                li {
                    width: calc(100% / 4);
                    img {
                        height: 100px;
                    }
                    p {
                        letter-spacing: 4px;
                    }
                    p.desc {
                        font-size: 16px;
                        letter-spacing: 1px;
                        margin-top: 15px;
                    }
                }
            }
        }
        .standart-btn {
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 16px;
            padding: 10px;
        }
    }
    .artists {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .artists-team {
        padding-top: 40px;
        padding-bottom: 40px;
        .review {
            &:before {
                display: none;
            }
        }
        .artist-item {
            width: calc(100% / 4 - 5px);
            height: 245px;
        }
    }
    #footer {
        padding-top: 40px;
        
        .inf {
            margin-top: 20px;
            .font-2 {
                margin-bottom: 10px;
            }
            .title-1 {
                margin-bottom: 10px;
            }
        }
        .overflow-container {
            padding-top: 20px;
        }
        .br-1, .br-2, .br-3, .br-4 {
            max-width: 100%;
        }
        .footer-brand {
            min-height: 80px;
        }
    }
    .services .service-item .bg-container {
        transform: translate(-20%, 0) scale(0.7);
    }
    // #team>div>div:nth-child(3)>div:nth-child(4) {
    //     background-position-y: 0;
    // }
    .popup-wraper .close-popup {
        right: -20px;
    }
    .reviews .team-item .avatar-block {
        margin-bottom: 30px;
    }
    .popup textarea {
        height: 28px;
    }
}

// 769 - 992px
@media screen and (min-width: 769px) and (max-width: 992px) {
  .grid-title--big .shadow {
    font-size: 50px;
    top: -10px;
    letter-spacing: 40px;
  }
    #header {
        .container {
            width: 90%;
        }
    }
    .main-nav {
        ul {
            li {
                margin-right: 25px;
                a {
                    font-size: 14px;
                }
            }
        }
    }
    .artists, .artists-team {
        .artist-item {
            width: calc(100% / 5 - 5px);
            margin-right: 0;
        }
        .wide-img {
            text-align: center;
        }
    }
    .reviews {
        .client-item {
            img {
                max-width: 60%;
            }
        }
    }
    .services {
        .services-block {
            padding-top: 0;
            ul {
                li {
                    width: calc(100% / 4);
                    img {
                        height: 100px;
                    }
                    p.desc {
                        margin-top: 30px;
                        font-size: 25px;
                        letter-spacing: 1px;
                    }
                }
            }
        }
    } 
    .artists-team {
        .artist-item {
            width: calc(100% / 4 - 5px);
        }
    }
    // #team>div>div:nth-child(3)>div:nth-child(4) {
    //     background-position-y: 0;
    // }
    .popup {
        &-wraper {
            width: 90%;
            top: 40px;
            transform: translate(-50%, 0);
        }
        input, textarea {
            position: relative;
        }
        label, input, textarea {
            display: block;
            font-size: 16px;
            max-width: 100%
        }
        .standart-btn {
            width: 100%;
        }
    }
    .reviews .team-item .avatar-block {
        margin-bottom: 30px;
    }
}

// 993 - 1200px
@media screen and (min-width: 993px) and (max-width: 1200px) {
    .services {
        background-position-y: 20px;
        background-size: 90% auto;
        .services-block {
            padding-top: 0;
            ul {
                li {
                    img {
                        height: 100px;
                    }
                    p.desc {
                        margin-top: 30px;
                        font-size: 25px;
                    }
                }
            }
        }
    }
    .main-nav {
        ul {
            li {
                margin-right: 25px;
                a {
                    font-size: 14px;
                }
            }
        }
    }
}

// 1201 - 1366px
@media screen and (min-width: 1201px) and (max-width: 1366px) {
    .services {
        background-position-y: 80px;
        background-size: 100% auto;
    }
}

// 1367 - more
@media screen and (min-width: 1367px) and (max-width: 1600px) {
    .services {
        background-position-y: 80px;
        background-size: 90% auto;
    }
}

