@charset "UTF-8";
@import url(../css/bootstrap.css);
@font-face {
  font-family: 'Lato Thin';
  src: url("../fonts/Lato-Thin.ttf"); }

@font-face {
  font-family: 'Lato Hairline';
  src: url("../fonts/Lato-Hairline.ttf"); }

.font-1 {
  font-family: "Lato Hairline", sans-serif;
  font-size: 20px;
  color: #2b2b2b;
  text-transform: uppercase;
  letter-spacing: 10px;
  line-height: normal;
  font-weight: bold; }

.font-3 {
  font-family: "Lato Thin", sans-serif;
  font-size: 22px;
  color: #2b2b2b;
  letter-spacing: .1px;
  line-height: 12px;
  font-weight: bold;
  margin-top: 50px; }

.font-5 {
  font-family: "Lato Hairline", sans-serif;
  font-size: 28px;
  color: #2b2b2b;
  letter-spacing: .1px;
  line-height: 26px;
  font-weight: bold;
  margin: 0 0 5px 0;
  text-transform: uppercase; }
  .font-5_small {
    font-size: 20px;
    text-transform: none; }

.link-1 {
  font-family: "Lato Thin", sans-serif;
  font-size: 15px;
  color: #2b2b2b;
  text-transform: uppercase;
  letter-spacing: .5px;
  line-height: 10px;
  text-decoration: none;
  font-weight: bold; }
  .link-1:hover {
    text-decoration: none;
    color: #2b2b2b; }

.link-2 {
  display: block;
  font-family: "Lato Thin", sans-serif;
  font-size: 20px;
  color: #2b2b2b;
  text-transform: uppercase;
  letter-spacing: .5px;
  line-height: normal;
  text-decoration: none;
  font-weight: bold;
  margin-top: 25px;
  padding-top: 20px;
  position: relative; }
  .link-2:hover {
    text-decoration: none;
    color: #2b2b2b; }
    .link-2:hover:before {
      width: 100%; }
  .link-2:before {
    content: '';
    display: bclock;
    width: 40%;
    height: 2px;
    background-color: #000000;
    transition: all .3s ease-in-out;
    position: absolute;
    top: 0;
    left: 0; }

.font-2 {
  font-family: "Lato Hairline", sans-serif;
  font-size: 30px;
  color: #2b2b2b;
  line-height: normal;
  font-weight: bold; }

.title-1 {
  font-family: "Lato Hairline", sans-serif;
  font-size: 33px;
  color: #2b2b2b;
  line-height: normal;
  font-weight: bold;
  margin-bottom: 37px; }

.title-2 {
  font-family: "Lato Hairline", sans-serif;
  font-size: 18px;
  color: #000000;
  line-height: normal;
  font-weight: bold;
  margin-bottom: 50px; }

.title-3 {
  font-family: "Lato Hairline", sans-serif;
  font-size: 24px;
  color: #2b2b2b;
  line-height: normal;
  font-weight: bold;
  margin-bottom: 40px; }

.title-4 {
  font-family: "Lato Hairline", sans-serif;
  font-size: 35px;
  color: #2b2b2b;
  line-height: 65px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 12px;
  margin-top: 114px;
  margin-bottom: 20px; }
  .title-4__subtitle {
    font-size: 22px; }
  .title-4__bigger {
    font-size: 50px; }
  .title-4.about-title {
    margin-top: 30px; }

.content-1 {
  font-family: "Lato Hairline", sans-serif;
  font-size: 25px;
  color: #2b2b2b;
  line-height: normal;
  font-weight: bold; }

.content-2 {
  font-family: "Lato Hairline", sans-serif;
  font-size: 14px;
  color: #2b2b2b;
  line-height: normal;
  font-weight: bold;
  margin: 0 auto;
  max-width: 215px; }

body, html {
  min-width: 320px; }

.artist-title {
  font-family: "Lato Hairline", sans-serif;
  font-size: 30px;
  text-transform: uppercase;
  color: #2b2b2b;
  letter-spacing: 12px;
  margin-top: 55px;
  margin-bottom: 30px; }
  .artist-title div.overflow-container {
    overflow: hidden;
    margin: 0;
    padding: 0; }
  .artist-title h3 {
    margin: 0;
    padding: 0;
    position: relative;
    top: 5px;
    font-weight: 700; }

#art .artist-title h3 {
  top: 6px; }

.content-1 span.text-right {
  display: block;
  width: 100%; }

.overflow-container {
  overflow: hidden; }
  .overflow-container .title-4 {
    display: inline-block;
    margin: 0;
    padding: 0;
    position: relative;
    top: 8px;
    line-height: normal; }

a, * {
  text-decoration-color: #000000; }

.text-line {
  text-align: center;
  padding: 100px 0 0 0; }
  .text-line p {
    font-family: "Lato Hairline", sans-serif;
    line-height: 40px;
    font-size: 21px; }

.and-more {
  font-family: "Lato Thin", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(43, 43, 43, 0.55);
  font-weight: 700; }

.mb {
  margin-bottom: 60px; }

.shadow {
  font-family: "Lato Hairline",sans-serif;
  font-size: 20px;
  color: #2b2b2b;
  text-transform: uppercase;
  letter-spacing: 15px;
  line-height: normal;
  font-weight: 800;
  position: absolute;
  left: 50%;
  top: 3px;
  opacity: .12;
  white-space: nowrap;
  transform: translate(-50%, 0) scale(1.1);
  max-width: 100%;
  overflow: hidden; }

.text-line .overflow-container {
  margin: 0 0 100px 0;
  padding: 0; }
  .text-line .overflow-container p {
    position: relative;
    margin: 0;
    padding: 0;
    top: 13px; }

body > section.reviews > div > div:nth-child(2) > div:nth-child(1) > div.about-block > div > p:nth-child(2) {
  white-space: nowrap; }

#header {
  padding-bottom: 50px; }
  #header .container {
    width: 880px; }

.main-nav {
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999; }
  .main-nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative; }
    .main-nav ul:after {
      content: '';
      display: block;
      width: 100%;
      height: 6px;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.05) 20%, rgba(255, 255, 255, 0.05) 70%);
      background: -moz-linear-gradient(to top, rgba(0, 0, 0, 0.05) 20%, rgba(255, 255, 255, 0.05) 70%);
      background: -o-linear-gradient(to top, rgba(0, 0, 0, 0.05) 20%, rgba(255, 255, 255, 0.05) 70%);
      background: -webkit-linear-gradient(to top, rgba(0, 0, 0, 0.05) 20%, rgba(255, 255, 255, 0.05) 70%);
      position: absolute;
      bottom: 0;
      left: 0; }
    .main-nav ul li {
      display: inline-block;
      margin-right: 50px;
      position: relative;
      top: 7px; }
      .main-nav ul li:last-child {
        margin-right: 0; }
      .main-nav ul li a {
        font-family: "Lato Thin", sans-serif;
        font-size: 20px;
        color: #2b2b2b;
        text-transform: uppercase;
        text-decoration: none; }

.socials-nav {
  list-style: none;
  margin: 0;
  padding: 0; }
  .socials-nav li {
    display: inline-block;
    margin-right: 37px; }
    .socials-nav li:last-child {
      margin-right: 0; }
    .socials-nav li a img {
      width: 80px;
      transition: all .5s ease-in-out;
      transform-origin: 50% 50%; }

.bg-wraper {
  background-image: url(../img/background.png);
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: auto;
  background-color: #ffffff;
  animation-name: bg-anim-1;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-timing-function: linear; }

.main-banner {
  padding: 55px 0 0 0; }
  .main-banner .logo {
    display: inline-block;
    margin-bottom: 45px; }
  .main-banner .overflow-container {
    overflow: hidden; }
    .main-banner .overflow-container p {
      position: relative;
      top: 5px;
      margin: 0;
      padding: 0;
      line-height: normal; }

.icons-list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .icons-list li {
    display: inline-block;
    margin-right: 45px; }
    .icons-list li:last-child {
      margin-right: 0; }
    .icons-list li a img {
      display: block;
      width: 80px;
      height: auto;
      margin: 32px auto 15px auto;
      transition: all .5s ease-in-out;
      transform-origin: 50% 50%; }
    .icons-list li a.logo img {
      transition: none; }
    .icons-list li a.logo:hover {
      transform: none; }

.text-block {
  padding: 80px 0 0 0; }
  .text-block_no-pt {
    padding-top: 0; }

.services {
  padding: 80px 0 0 0;
  background-image: url(../img/service-bg.png);
  background-repeat: no-repeat;
  background-position: center 55px;
  background-size: 70% auto;
  animation-name: bg-anim-2;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  animation-timing-function: linear; }
  .services_no-bg {
    background-image: none; }
  .services .service-item {
    min-height: 162px;
    margin-bottom: 30px;
    text-align: center;
    position: relative; }
    .services .service-item .bg-container {
      display: inline-block;
      position: relative; }
      .services .service-item .bg-container img {
        display: block; }
    .services .service-item .overflow-container {
      width: 95%;
      height: 95%;
      top: 0;
      left: 10px;
      padding-top: 95%;
      border-radius: 50%;
      margin: 0 auto;
      overflow: hidden;
      position: absolute; }
    .services .service-item .link-2 {
      display: block;
      margin: 0;
      padding: 0;
      text-align: left;
      width: auto;
      max-width: 160px;
      height: auto;
      position: absolute;
      left: -3px;
      top: 50%;
      transform: translate(0, -50%);
      text-decoration: none !important;
      color: #2b2b2b !important;
      cursor: default; }
      .services .service-item .link-2:before {
        display: none; }
    .services .service-item:nth-child(3) .overflow-container {
      left: 6px; }
    .services .service-item:last-child .overflow-container {
      left: -4px; }
    .services .service-item:last-child .link-2 {
      text-align: right;
      left: auto;
      right: -4px; }
  .services .standart-btn {
    margin-bottom: 50px; }
  .services .services-block {
    padding: 50px 0; }
    .services .services-block ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-around;
      flex-flow: row wrap; }
      .services .services-block ul li {
        display: block;
        width: calc(100% / 4);
        height: auto;
        margin-bottom: 30px; }
        .services .services-block ul li img {
          display: block;
          height: 180px;
          margin: 0 auto 30px auto; }
        .services .services-block ul li a {
          color: #2b2b2b !important;
          text-decoration: none !important; }
        .services .services-block ul li p {
          font-family: "Lato Thin", sans-serif;
          font-size: 21px;
          text-transform: uppercase;
          text-align: center;
          margin: 0;
          padding: 0;
          letter-spacing: 8px; }
          .services .services-block ul li p.desc {
            font-size: 18px;
            margin: 50px 0 0 0; }

.artists, .artists-team {
  padding: 120px 0 55px 0; }
  .artists .overflow-container, .artists-team .overflow-container {
    overflow: hidden;
    margin-bottom: 37px; }
    .artists .overflow-container h2, .artists-team .overflow-container h2 {
      position: relative;
      top: 8px;
      margin: 0;
      padding: 0;
      line-height: normal; }
  .artists .artist-item, .artists-team .artist-item {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    display: inline-block;
    width: calc(100% / 5 - 5px);
    height: 188px;
    margin: 0 1px 2px 0;
    background-color: #eaeaea; }
    .artists .artist-item__hover, .artists-team .artist-item__hover {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: #eaeaea;
      opacity: 0;
      transition: all .3s ease-in-out; }
      .artists .artist-item__hover span, .artists-team .artist-item__hover span {
        display: block;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        letter-spacing: .1px;
        text-align: left; }
      .artists .artist-item__hover:hover, .artists-team .artist-item__hover:hover {
        opacity: 1; }

.artists {
  padding-top: 50px; }
  .artists_about .artist-item {
    background-position: center top; }
    .artists_about .artist-item__hover span {
      width: auto; }

.artists-team .overflow-container h2 {
  top: 8px; }

.artists-team .artist-item {
  width: calc(100% / 4 - 15px);
  height: 237px;
  background-color: #d7d7d7;
  background-size: cover;
  background-position: center top;
  margin-right: 10px; }
  .artists-team .artist-item .content-2 {
    margin-top: 30px;
    text-transform: none; }
  .artists-team .artist-item__hover {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #eaeaea;
    opacity: 0;
    transition: all .3s ease-in-out; }
    .artists-team .artist-item__hover span {
      display: block;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      letter-spacing: .1px;
      text-align: left;
      line-height: normal; }
      .artists-team .artist-item__hover span.font-1 {
        text-transform: none; }
      .artists-team .artist-item__hover span .content-2 {
        margin-top: 0; }
    .artists-team .artist-item__hover:hover {
      opacity: 1; }

.artists-team .wide-img {
  margin-bottom: 30px;
  text-align: right; }
  .artists-team .wide-img .artist-item {
    width: auto;
    height: auto; }
    .artists-team .wide-img .artist-item__hover {
      top: 0;
      left: 0; }
  .artists-team .wide-img img {
    display: inline-block; }

.artists-team .review {
  position: relative;
  text-align: justify; }
  .artists-team .review:before {
    content: '«';
    display: inline-block;
    font-family: "Lato Hairline", sans-serif;
    font-size: 410px;
    line-height: 70px;
    color: #e8e8e8;
    position: absolute;
    top: 0;
    left: -100px;
    z-index: -1;
    text-shadow: 0 2px 5px #e8e8e8,  0 -2px 5px #e8e8e8,  2px 0 5px #e8e8e8,  -2px 0 5px #e8e8e8; }

.reviews {
  padding: 75px 0 25px 0; }
  .reviews .overflow-container {
    overflow: hidden;
    margin-bottom: 120px; }
    .reviews .overflow-container .title-4 {
      margin: 0;
      position: relative;
      top: 8px; }
  .reviews .team-item {
    margin-bottom: 60px; }
    .reviews .team-item .avatar-block {
      width: 284px;
      height: 284px;
      border-radius: 50%;
      background-color: #e1e1e1;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto;
      margin-bottom: 20px; }
    .reviews .team-item .about-block {
      margin-bottom: 30px; }
      .reviews .team-item .about-block img {
        display: inline-block;
        vertical-align: top;
        margin-right: 40px; }
      .reviews .team-item .about-block .inf-wraper {
        display: inline-block;
        vertical-align: top; }
  .reviews .client-item {
    min-height: 170px; }
    .reviews .client-item img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
      .reviews .client-item img.cl-1 {
        max-width: 164px; }
      .reviews .client-item img.cl-2 {
        max-width: 243px; }
      .reviews .client-item img.cl-3 {
        max-width: 128px; }
      .reviews .client-item img.cl-4 {
        max-width: 188px; }
      .reviews .client-item img.cl-5 {
        max-width: 186px; }
      .reviews .client-item img.cl-6 {
        max-width: 177px; }
      .reviews .client-item img.cl-7 {
        max-width: 206px; }
      .reviews .client-item img.cl-8 {
        max-width: 185px; }
      .reviews .client-item img.cl-9 {
        max-width: 250px; }
      .reviews .client-item img.cl-10 {
        max-width: 160px; }
      .reviews .client-item img.cl-11 {
        max-width: 142px; }
      .reviews .client-item img.cl-12 {
        max-width: 64px;
        top: 45%; }
      .reviews .client-item img.cl-13 {
        max-width: 219px; }
      .reviews .client-item img.cl-14 {
        max-width: 135px; }
      .reviews .client-item img.cl-15 {
        max-width: 214px; }
      .reviews .client-item img.cl-16 {
        max-width: 144px; }
      .reviews .client-item img.cl-17 {
        max-width: 173px; }
  .reviews .review {
    position: relative;
    margin-top: 10px; }
    .reviews .review .content-1 {
      text-align: justify; }
    .reviews .review:before {
      content: '«';
      display: inline-block;
      font-family: "Lato Hairline", sans-serif;
      font-size: 175px;
      line-height: 32px;
      color: #e8e8e8;
      position: absolute;
      top: 0;
      left: -75px; }

.contacts {
  padding: 55px 0; }

.contacts-mail-section {
  margin-top: 40px;
  margin-bottom: 150px; }
  .contacts-mail-section .font-2 {
    font-size: 40px; }

.popup {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: #ffffff;
  justify-content: center;
  align-items: center; }
  .popup-wraper {
    width: 100%;
    max-width: 520px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 30px; }
    .popup-wraper .close-popup {
      position: absolute;
      right: -30px;
      top: 0;
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      transition: all .3s ease-in-out; }
      .popup-wraper .close-popup span {
        position: absolute;
        display: block;
        width: 30px;
        height: 2px;
        background-color: #2b2b2b;
        left: 50%;
        top: 50%;
        transform-origin: 50% 50%;
        transition: all .3s ease-in-out; }
        .popup-wraper .close-popup span:first-child {
          transform: translate(-50%, -50%) rotate(45deg); }
        .popup-wraper .close-popup span:last-child {
          transform: translate(-50%, -50%) rotate(-45deg); }
      .popup-wraper .close-popup:hover {
        background-color: #000000; }
        .popup-wraper .close-popup:hover span {
          background-color: #fff; }
  .popup form div {
    position: relative;
    margin-bottom: 30px; }
  .popup input {
    width: 100%;
    max-width: 390px;
    position: absolute;
    right: 0;
    border: none;
    border-bottom: 1px solid #5d5d5d;
    font-family: "Lato Hairline", sans-serif;
    font-size: 20px;
    color: #2b2b2b;
    text-transform: uppercase;
    outline: none;
    font-weight: bold; }
  .popup label {
    font-family: "Lato Hairline", sans-serif;
    font-size: 20px;
    color: #2b2b2b;
    text-transform: uppercase; }
  .popup p {
    font-family: "Lato Hairline", sans-serif;
    font-size: 20px;
    color: #2b2b2b;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold; }
  .popup textarea {
    height: 33px;
    border: none;
    border-bottom: 1px solid #5d5d5d;
    font-family: "Lato Hairline", sans-serif;
    font-size: 20px;
    color: #2b2b2b;
    text-transform: uppercase;
    outline: none;
    font-weight: bold;
    resize: vertical;
    position: absolute;
    right: 0;
    width: 100%;
    max-width: 390px; }

.talent .content-1, .mission .content-1, .text-block .content-1,
.why-choose-us .content-1, .contacts-about .content-1 {
  font-size: 31px;
  margin-top: 50px; }
  .talent .content-1 a, .mission .content-1 a, .text-block .content-1 a,
  .why-choose-us .content-1 a, .contacts-about .content-1 a {
    color: #2b2b2b; }

.why-choose-us {
  padding-bottom: 0; }

.contacts-about {
  padding-top: 100px; }

.text-block .content-1 {
  margin-top: 0; }

.mission {
  padding: 100px 0 0 0; }

@keyframes bg-anim-1 {
  0% {
    background-position: 50% 100%; }
  25% {
    background-position: 51% 97%; }
  50% {
    background-position: 50% 94%; }
  75% {
    background-position: 49% 97%; }
  100% {
    background-position: 50% 100%; } }

@keyframes bg-anim-2 {
  0% {
    background-position-x: 50%; }
  25% {
    background-position-x: 51%; }
  50% {
    background-position-x: 50%; }
  75% {
    background-position-x: 49%; }
  100% {
    background-position-x: 50%; } }

.menu-btn {
  display: none;
  width: 40px;
  height: 20px;
  position: relative; }
  .menu-btn span {
    position: absolute;
    width: 30px;
    height: 2px;
    background-color: #000;
    left: 5px;
    transform-origin: 50% 50%;
    transition: all .3s ease-in-out; }
    .menu-btn span:first-child {
      top: 0; }
    .menu-btn span:nth-child(2) {
      top: 50%;
      transform: translate(0, -50%);
      opacity: 1; }
    .menu-btn span:last-child {
      top: 100%;
      transform: translate(0, -2px); }
  .menu-btn.active span:first-child {
    top: 50%;
    transform: translate(0, -50%) rotate(45deg); }
  .menu-btn.active span:nth-child(2) {
    opacity: 0; }
  .menu-btn.active span:last-child {
    top: 50%;
    transform: translate(0, -50%) rotate(135deg); }

.standart-btn {
  font-family: "Lato Hairline", sans-serif;
  display: block;
  padding: 23px;
  max-width: 360px;
  margin: 30px auto 0 auto;
  text-decoration: none !important;
  text-transform: uppercase;
  font-size: 20px;
  color: #5d5d5d;
  border: 2px solid #5d5d5d;
  text-align: center;
  font-weight: bold;
  transition: all .3s ease-in-out;
  outline: none;
  background: none; }
  .standart-btn:hover {
    text-decoration: none;
    color: #fff;
    background-color: #000000; }

button.standart-btn {
  width: 260px;
  max-width: 260px;
  border: 1px solid #5d5d5d; }

.mt {
  margin-top: 4px !important; }

.grid-title {
  margin-bottom: 30px; }
  .grid-title .overflow-container {
    margin: 0 0 100px 0;
    padding: 0; }
  .grid-title--big {
    margin-top: 50px; }
    .grid-title--big .font-1 {
      font-size: 30px;
      position: relative;
      top: 19px; }
    .grid-title--big .shadow {
      font-size: 70px;
      top: -15px;
      letter-spacing: 60px; }

.grid-container {
  display: inline-block;
  margin-top: -8px;
  width: 100%;
  max-width: 995px; }

.g-box {
  background-color: #eee;
  float: left;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  z-index: 200;
  width: 100%; }
  .g-box:hover .bg-col {
    opacity: 1; }
  .g-box .bg-col {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 300;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    opacity: 0;
    transition: all .2s ease-in; }
  .g-box .caption {
    position: absolute;
    z-index: 400;
    padding: 15px;
    font-family: "Lato Thin", sans-serif;
    font-size: 17px;
    line-height: 19px;
    font-weight: 900;
    text-shadow: .5px 0 0px #2b2b2b; }
    .g-box .caption.top {
      top: 0; }
    .g-box .caption.left {
      left: 0; }
    .g-box .caption.right {
      right: 0; }
    .g-box .caption.bottom {
      bottom: 0; }
    .g-box .caption.dark {
      color: #2b2b2b; }
    .g-box .caption.white {
      color: #fff; }

.g-box-wraper {
  float: left;
  margin: 0 2px;
  width: 100%; }
  .g-box-wraper .g-box {
    float: none;
    margin: 4px 0; }
  .g-box-wraper .g-box-wraper {
    margin: -4px 2px 0 0; }
    .g-box-wraper .g-box-wraper:last-child {
      margin-right: 0; }

.text-uppercase {
  font-variant: small-caps; }

.w-1 {
  width: 195px; }

.w-2 {
  width: 394px; }

.w-3 {
  width: 593px; }

.w-4 {
  width: 788px; }

.w-33 {
  width: calc(33.33% - 4px); }

.w-50 {
  width: calc(50% - 4px); }

.w-100 {
  width: 100%; }

.h-1 {
  height: 195px; }

.h-11 {
  height: 216.5px; }

.h-12 {
  height: 236px; }

.h-13 {
  height: 255.5px; }

.h-14 {
  height: 275px; }

.h-15 {
  height: 294.5px; }

.h-16 {
  height: 314px; }

.h-17 {
  height: 333.5px; }

.h-18 {
  height: 353px; }

.h-19 {
  height: 372.5px; }

.h-2 {
  height: 394px; }

.h-3 {
  height: 593px; }

.h-offset-1 {
  margin-left: 199px !important; }

@media screen and (max-width: 480px) {
  .w-1 {
    width: 53.57143px; }
  .w-2 {
    width: 111.14286px; }
  .w-3 {
    width: 168.71429px; }
  .w-4 {
    width: 222.28571px; }
  .h-1 {
    height: 53.57143px; }
  .h-11 {
    height: 60.92857px; }
  .h-12 {
    height: 66.28571px; }
  .h-13 {
    height: 71.64286px; }
  .h-14 {
    height: 77px; }
  .h-15 {
    height: 82.35714px; }
  .h-16 {
    height: 87.71429px; }
  .h-17 {
    height: 93.07143px; }
  .h-18 {
    height: 98.42857px; }
  .h-19 {
    height: 103.78571px; }
  .h-2 {
    height: 111.14286px; }
  .h-3 {
    height: 168.71429px; }
  .h-offset-1 {
    margin-left: 57.57143px !important; }
  .g-box .caption {
    padding: 5px;
    font-size: 12px;
    line-height: 12px; }
  .grid-container {
    max-width: 290px; } }

@media screen and (min-width: 481px) and (max-width: 768px) {
  .w-1 {
    width: 86.13074px; }
  .w-2 {
    width: 176.26148px; }
  .w-3 {
    width: 266.39223px; }
  .w-4 {
    width: 352.52297px; }
  .h-1 {
    height: 86.13074px; }
  .h-11 {
    height: 96.74382px; }
  .h-12 {
    height: 105.35689px; }
  .h-13 {
    height: 113.96996px; }
  .h-14 {
    height: 122.58304px; }
  .h-15 {
    height: 131.19611px; }
  .h-16 {
    height: 139.80919px; }
  .h-17 {
    height: 148.42226px; }
  .h-18 {
    height: 157.03534px; }
  .h-19 {
    height: 165.64841px; }
  .h-2 {
    height: 176.26148px; }
  .h-3 {
    height: 266.39223px; }
  .h-offset-1 {
    margin-left: 90.13074px !important; }
  .g-box .caption {
    padding: 10px;
    font-size: 14px;
    line-height: 14px; }
  .grid-container {
    max-width: 451px; } }

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .w-1 {
    width: 139.88522px; }
  .w-2 {
    width: 283.77044px; }
  .w-3 {
    width: 427.65567px; }
  .w-4 {
    width: 567.54089px; }
  .h-1 {
    height: 139.88522px; }
  .h-11 {
    height: 155.87374px; }
  .h-12 {
    height: 169.86227px; }
  .h-13 {
    height: 183.85079px; }
  .h-14 {
    height: 197.83931px; }
  .h-15 {
    height: 211.82783px; }
  .h-16 {
    height: 225.81636px; }
  .h-17 {
    height: 239.80488px; }
  .h-18 {
    height: 253.7934px; }
  .h-19 {
    height: 267.78192px; }
  .h-2 {
    height: 283.77044px; }
  .h-3 {
    height: 427.65567px; }
  .h-offset-1 {
    margin-left: 143.88522px !important; }
  .g-box .caption {
    padding: 10px; }
  .grid-container {
    max-width: 720px; } }

#footer {
  padding: 55px 0 0 0; }
  #footer .inf {
    margin-top: 60px; }
    #footer .inf .font-2 {
      display: block;
      margin: 0 0 30px 0; }
      #footer .inf .font-2:hover {
        color: #2b2b2b; }
  #footer .footer-brand {
    min-height: 200px; }
    #footer .footer-brand img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  #footer .overflow-container {
    padding-top: 50px;
    overflow: hidden; }
    #footer .overflow-container h2 {
      position: relative;
      margin: 0;
      padding: 0;
      line-height: normal;
      top: 8px; }
  #footer .br-1 {
    max-width: 167px; }
  #footer .br-2 {
    max-width: 265px; }
  #footer .br-3 {
    max-width: 185px; }
  #footer .br-4 {
    max-width: 234px; }
  #footer a {
    color: #2b2b2b !important; }
  #footer .standart-btn {
    max-width: 350px; }
  #footer .standart-btn:hover {
    color: #ffffff !important; }
  #footer .copyright-section {
    background-color: #d5d5d5;
    padding-top: 35px;
    padding-bottom: 35px;
    position: relative;
    z-index: 500; }
    #footer .copyright-section p {
      margin: 0;
      padding: 0;
      font-family: "Lato Thin", sans-serif;
      font-size: 10px;
      color: rgba(43, 43, 43, 0.7);
      font-weight: 700; }
    #footer .copyright-section__contacts {
      margin-top: 30px; }

@media screen and (min-width: 0px) and (max-width: 320px) {
  .grid-title--big .shadow {
    font-size: 35px;
    top: 6px;
    letter-spacing: 18px; }
  #header {
    padding: 0; }
    #header .container {
      width: 90%; }
  .main-nav {
    padding: 20px 0; }
    .main-nav ul {
      display: none; }
      .main-nav ul li {
        display: block;
        margin: 0 0 10px 0; }
    .main-nav.visible ul {
      display: block; }
  .menu-btn {
    display: block; }
  .icons-list li {
    margin: 10px; }
    .icons-list li a img {
      width: 40px; }
  .font-1, .title-1 {
    font-size: 18px; }
  .font-2 {
    font-size: 17px; }
  .font-5 {
    font-size: 14px;
    line-height: 16px; }
  .content-1 {
    font-size: 14px; }
  .title-4 {
    margin-top: 20px;
    font-size: 16px;
    letter-spacing: 4px;
    line-height: 30px; }
    .title-4__bigger {
      font-size: 30px; }
  .overflow-container .title-4 {
    top: 6px; }
  .socials-nav li {
    margin: 15px; }
    .socials-nav li a img {
      width: 40px; }
  .artists .artist-item, .artists-team .artist-item {
    width: calc(100% / 5 - 5px);
    margin-right: 0;
    height: 75px; }
    .artists .artist-item__hover span.font-1, .artists .artist-item__hover span.content-1, .artists-team .artist-item__hover span.font-1, .artists-team .artist-item__hover span.content-1 {
      font-size: 10px; }
  .artists .wide-img, .artists-team .wide-img {
    text-align: center; }
  .popup-wraper {
    width: 90%;
    top: 40px;
    transform: translate(-50%, 0); }
  .popup input, .popup textarea {
    position: relative; }
  .popup label, .popup input, .popup textarea {
    font-size: 16px; }
  .popup .standart-btn {
    width: 100%; }
  .popup form div {
    margin-bottom: 10px; }
  .reviews {
    padding-top: 20px;
    padding-bottom: 20px; }
    .reviews .client-item {
      min-height: 80px; }
      .reviews .client-item img.cl-1, .reviews .client-item img.cl-2, .reviews .client-item img.cl-3, .reviews .client-item img.cl-4, .reviews .client-item img.cl-5, .reviews .client-item img.cl-6, .reviews .client-item img.cl-7, .reviews .client-item img.cl-8, .reviews .client-item img.cl-9, .reviews .client-item img.cl-10, .reviews .client-item img.cl-11, .reviews .client-item img.cl-12, .reviews .client-item img.cl-13, .reviews .client-item img.cl-14, .reviews .client-item img.cl-15, .reviews .client-item img.cl-16, .reviews .client-item img.cl-17 {
        max-width: 90%; }
      .reviews .client-item img.cl-12 {
        max-width: 50%; }
    .reviews .overflow-container {
      margin-bottom: 20px; }
      .reviews .overflow-container .title-4 {
        top: 6px; }
    .reviews .team-item {
      margin-bottom: 20px; }
      .reviews .team-item .avatar-block {
        width: 120px;
        height: 120px;
        background-size: cover; }
      .reviews .team-item img {
        max-width: 50%;
        margin-top: -15px; }
      .reviews .team-item .about-block {
        margin-bottom: 0;
        height: 125px; }
    .reviews .review {
      top: 10px; }
      .reviews .review:before {
        font-size: 100px;
        line-height: 18px;
        left: -35px; }
  .services {
    padding-top: 45px;
    background-size: 100% auto;
    background-position: center 40px; }
    .services .services-block {
      padding-top: 0;
      padding-bottom: 0; }
      .services .services-block ul li {
        width: calc(100% / 4); }
        .services .services-block ul li img {
          height: 65px; }
        .services .services-block ul li p {
          font-size: 14px;
          letter-spacing: 2px; }
        .services .services-block ul li p.desc {
          font-size: 8px;
          margin-top: 15px;
          letter-spacing: 1px; }
    .services .standart-btn {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 16px;
      padding: 10px; }
  .services_no-bg {
    padding-top: 0;
    margin: -25px 0 -50px 0; }
    .services_no-bg + .text-block {
      padding-top: 0; }
  .artist-title {
    font-size: 18px;
    margin-top: 10px; }
    .artist-title h3 {
      top: 3px;
      font-size: 16px; }
  .artists {
    padding-top: 20px;
    padding-bottom: 20px; }
    .artists .overflow-container h2 {
      top: 5px; }
  .standart-btn {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    padding: 10px; }
  .artists-team {
    padding-top: 20px;
    padding-bottom: 20px; }
    .artists-team .review:before {
      display: none; }
    .artists-team .artist-item {
      width: calc(100% / 2 - 5px);
      height: 150px; }
    .artists-team .overflow-container h2 {
      top: 6px; }
  #footer {
    padding-top: 20px; }
    #footer .inf {
      margin-top: 20px; }
      #footer .inf .font-2 {
        margin-bottom: 10px; }
      #footer .inf .title-1 {
        margin-bottom: 10px; }
    #footer .overflow-container {
      padding-top: 20px; }
      #footer .overflow-container h2 {
        top: 6px; }
    #footer .br-1, #footer .br-2, #footer .br-3, #footer .br-4 {
      max-width: 100%; }
    #footer .footer-brand {
      min-height: 80px; }
  #art .artist-title h3 {
    top: 3px; }
  section, .mission, .contacts-about {
    padding: 20px 0; }
  .contacts-about .content-1, .mission .content-1, .talent .content-1, .text-block .content-1, .why-choose-us .content-1 {
    font-size: 16px;
    margin-top: 20px; }
  .services .service-item .bg-container {
    transform: translate(-35%, 0) scale(0.4); }
  body > section.reviews > div > div:nth-child(2) > div:nth-child(3) > div.about-block > div:nth-child(1) > img {
    max-width: 100%; }
  .popup-wraper .close-popup {
    right: -10px; }
  body > section.reviews > div > div:nth-child(2) > div:nth-child(1) > div.about-block > div:nth-child(1) > img {
    width: 40%; }
  .popup textarea {
    height: 28px; }
  .contacts-mail-section {
    margin-bottom: 60px; }
    .contacts-mail-section .font-2 {
      font-size: 23px; }
  .title-4.about-title {
    margin-top: 20px;
    margin-bottom: 0; } }

@media screen and (min-width: 321px) and (max-width: 480px) {
  .grid-title--big .shadow {
    font-size: 35px;
    top: 6px;
    letter-spacing: 18px; }
  #header {
    padding: 0; }
    #header .container {
      width: 90%; }
  .main-nav {
    padding: 20px 0; }
    .main-nav ul {
      display: none; }
      .main-nav ul li {
        display: block;
        margin: 0 0 10px 0; }
    .main-nav.visible ul {
      display: block; }
  .menu-btn {
    display: block; }
  .icons-list li {
    margin: 10px; }
    .icons-list li a img {
      width: 40px; }
  .font-1, .title-1 {
    font-size: 18px; }
  .font-2 {
    font-size: 17px; }
  .font-5 {
    font-size: 14px;
    line-height: 16px; }
  .content-1 {
    font-size: 14px; }
  .title-4 {
    margin-top: 20px;
    font-size: 16px;
    letter-spacing: 4px;
    line-height: 30px; }
    .title-4__bigger {
      font-size: 30px; }
  .overflow-container .title-4 {
    top: 6px; }
  .socials-nav li {
    margin: 15px; }
    .socials-nav li a img {
      width: 40px; }
  .artists .artist-item, .artists-team .artist-item {
    width: calc(100% / 5 - 5px);
    margin-right: 0;
    height: 104px; }
    .artists .artist-item__hover span.font-1, .artists .artist-item__hover span.content-1, .artists-team .artist-item__hover span.font-1, .artists-team .artist-item__hover span.content-1 {
      font-size: 10px; }
  .artists .wide-img, .artists-team .wide-img {
    text-align: center; }
  .popup-wraper {
    width: 90%;
    top: 40px;
    transform: translate(-50%, 0); }
  .popup input, .popup textarea {
    position: relative; }
  .popup label, .popup input, .popup textarea {
    font-size: 16px; }
  .popup .standart-btn {
    width: 100%;
    max-width: 100%; }
  .popup form div {
    margin-bottom: 10px; }
  .reviews {
    padding-top: 20px;
    padding-bottom: 20px; }
    .reviews .client-item {
      min-height: 100px; }
      .reviews .client-item img.cl-1, .reviews .client-item img.cl-2, .reviews .client-item img.cl-3, .reviews .client-item img.cl-4, .reviews .client-item img.cl-5, .reviews .client-item img.cl-6, .reviews .client-item img.cl-7, .reviews .client-item img.cl-8, .reviews .client-item img.cl-9, .reviews .client-item img.cl-10, .reviews .client-item img.cl-11, .reviews .client-item img.cl-12, .reviews .client-item img.cl-13, .reviews .client-item img.cl-14, .reviews .client-item img.cl-15, .reviews .client-item img.cl-16, .reviews .client-item img.cl-17 {
        max-width: 90%; }
      .reviews .client-item img.cl-12 {
        max-width: 50%; }
    .reviews .overflow-container {
      margin-bottom: 20px; }
      .reviews .overflow-container .title-4 {
        top: 6px; }
    .reviews .team-item {
      margin-bottom: 20px; }
      .reviews .team-item .avatar-block {
        width: 120px;
        height: 120px;
        background-size: cover; }
      .reviews .team-item img {
        max-width: 50%;
        margin-top: -15px; }
      .reviews .team-item .about-block {
        margin-bottom: 0;
        height: 125px; }
    .reviews .review {
      top: 20px; }
      .reviews .review:before {
        font-size: 100px;
        line-height: 18px;
        left: -35px; }
  .services {
    padding-top: 45px;
    padding-top: 45px;
    background-size: 100% auto;
    background-position: center 40px; }
    .services .services-block {
      padding-top: 0;
      padding-bottom: 0; }
      .services .services-block ul li {
        width: calc(100% / 4); }
        .services .services-block ul li img {
          height: 65px; }
        .services .services-block ul li p {
          font-size: 14px;
          letter-spacing: 2px; }
        .services .services-block ul li p.desc {
          font-size: 8px;
          margin-top: 15px;
          letter-spacing: 1px; }
    .services .standart-btn {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 16px;
      padding: 10px; }
  .services_no-bg {
    padding-top: 0;
    margin: -25px 0 -50px 0; }
    .services_no-bg + .text-block {
      padding-top: 0; }
  .artist-title {
    font-size: 18px;
    margin-top: 10px; }
    .artist-title h3 {
      font-size: 16px;
      top: 3px; }
  .artists {
    padding-top: 20px;
    padding-bottom: 20px; }
    .artists .overflow-container h2 {
      top: 5px; }
  .standart-btn {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    padding: 10px; }
  .artists-team {
    padding-top: 20px;
    padding-bottom: 20px; }
    .artists-team .review:before {
      display: none; }
    .artists-team .artist-item {
      width: calc(100% / 2 - 5px);
      height: 245px; }
    .artists-team .overflow-container h2 {
      top: 6px; }
  #footer {
    padding-top: 20px; }
    #footer .inf {
      margin-top: 20px; }
      #footer .inf .font-2 {
        margin-bottom: 10px; }
      #footer .inf .title-1 {
        margin-bottom: 10px; }
    #footer .overflow-container {
      padding-top: 20px; }
      #footer .overflow-container h2 {
        top: 6px; }
    #footer .br-1, #footer .br-2, #footer .br-3, #footer .br-4 {
      max-width: 100%; }
    #footer .footer-brand {
      min-height: 80px; }
  #art .artist-title h3 {
    top: 3px; }
  section, .mission, .contacts-about {
    padding: 20px 0; }
  .contacts-about .content-1, .mission .content-1, .talent .content-1, .text-block .content-1, .why-choose-us .content-1 {
    font-size: 16px;
    margin-top: 20px; }
  .services .service-item .bg-container {
    transform: translate(-35%, 0) scale(0.4); }
  body > section.reviews > div > div:nth-child(2) > div:nth-child(3) > div.about-block > div:nth-child(1) > img {
    max-width: 60%; }
  .popup-wraper .close-popup {
    right: -10px; }
  body > section.reviews > div > div:nth-child(2) > div:nth-child(1) > div.about-block > div:nth-child(1) > img {
    width: 40%; }
  .popup textarea {
    height: 28px; }
  .contacts-mail-section {
    margin-bottom: 60px; }
    .contacts-mail-section .font-2 {
      font-size: 23px; }
  .title-4.about-title {
    margin-top: 20px;
    margin-bottom: 0; } }

@media screen and (min-width: 481px) and (max-width: 768px) {
  .grid-title--big .shadow {
    font-size: 30px;
    top: 8px;
    letter-spacing: 40px; }
  #header {
    padding: 0; }
    #header .container {
      width: 90%; }
  .main-nav {
    padding: 20px 0; }
    .main-nav ul {
      display: none; }
      .main-nav ul li {
        display: block;
        margin: 0 0 10px 0; }
    .main-nav.visible ul {
      display: block; }
  .menu-btn {
    display: block; }
  .icons-list li {
    margin: 10px; }
    .icons-list li a img {
      width: 40px; }
  .socials-nav li {
    margin: 15px; }
    .socials-nav li a img {
      width: 40px; }
  .artists .artist-item, .artists-team .artist-item {
    width: calc(100% / 5 - 5px);
    margin-right: 0; }
  .artists .wide-img, .artists-team .wide-img {
    text-align: center; }
  .popup-wraper {
    width: 90%;
    top: 40px;
    transform: translate(-50%, 0); }
  .popup input, .popup textarea {
    position: relative; }
  .popup label, .popup input, .popup textarea {
    display: block;
    font-size: 16px;
    max-width: 100%; }
  .popup .standart-btn {
    width: 100%; }
  .popup form div {
    margin-bottom: 10px; }
  .reviews {
    padding-top: 40px;
    padding-bottom: 40px; }
    .reviews .client-item {
      min-height: 100px; }
      .reviews .client-item img.cl-1, .reviews .client-item img.cl-2, .reviews .client-item img.cl-3, .reviews .client-item img.cl-4, .reviews .client-item img.cl-5, .reviews .client-item img.cl-6, .reviews .client-item img.cl-7, .reviews .client-item img.cl-8, .reviews .client-item img.cl-9, .reviews .client-item img.cl-10, .reviews .client-item img.cl-11, .reviews .client-item img.cl-12, .reviews .client-item img.cl-13, .reviews .client-item img.cl-14, .reviews .client-item img.cl-15, .reviews .client-item img.cl-16, .reviews .client-item img.cl-17 {
        max-width: 90%; }
      .reviews .client-item img.cl-12 {
        max-width: 50%; }
    .reviews .overflow-container {
      margin-bottom: 20px; }
    .reviews .team-item {
      margin-bottom: 20px; }
      .reviews .team-item .avatar-block {
        width: 180px;
        height: 180px;
        background-size: cover; }
      .reviews .team-item img {
        max-width: 40%;
        margin-top: -23px; }
      .reviews .team-item .about-block {
        margin-bottom: 0;
        height: 180px; }
    .reviews .review:before {
      font-size: 100px;
      line-height: 18px;
      left: -35px; }
  .services {
    padding-top: 45px; }
    .services .services-block {
      padding-top: 0;
      padding-bottom: 0; }
      .services .services-block ul li {
        width: calc(100% / 4); }
        .services .services-block ul li img {
          height: 100px; }
        .services .services-block ul li p {
          letter-spacing: 4px; }
        .services .services-block ul li p.desc {
          font-size: 16px;
          letter-spacing: 1px;
          margin-top: 15px; }
    .services .standart-btn {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 16px;
      padding: 10px; }
  .artists {
    padding-top: 40px;
    padding-bottom: 40px; }
  .artists-team {
    padding-top: 40px;
    padding-bottom: 40px; }
    .artists-team .review:before {
      display: none; }
    .artists-team .artist-item {
      width: calc(100% / 4 - 5px);
      height: 245px; }
  #footer {
    padding-top: 40px; }
    #footer .inf {
      margin-top: 20px; }
      #footer .inf .font-2 {
        margin-bottom: 10px; }
      #footer .inf .title-1 {
        margin-bottom: 10px; }
    #footer .overflow-container {
      padding-top: 20px; }
    #footer .br-1, #footer .br-2, #footer .br-3, #footer .br-4 {
      max-width: 100%; }
    #footer .footer-brand {
      min-height: 80px; }
  .services .service-item .bg-container {
    transform: translate(-20%, 0) scale(0.7); }
  .popup-wraper .close-popup {
    right: -20px; }
  .reviews .team-item .avatar-block {
    margin-bottom: 30px; }
  .popup textarea {
    height: 28px; } }

@media screen and (min-width: 769px) and (max-width: 992px) {
  .grid-title--big .shadow {
    font-size: 50px;
    top: -10px;
    letter-spacing: 40px; }
  #header .container {
    width: 90%; }
  .main-nav ul li {
    margin-right: 25px; }
    .main-nav ul li a {
      font-size: 14px; }
  .artists .artist-item, .artists-team .artist-item {
    width: calc(100% / 5 - 5px);
    margin-right: 0; }
  .artists .wide-img, .artists-team .wide-img {
    text-align: center; }
  .reviews .client-item img {
    max-width: 60%; }
  .services .services-block {
    padding-top: 0; }
    .services .services-block ul li {
      width: calc(100% / 4); }
      .services .services-block ul li img {
        height: 100px; }
      .services .services-block ul li p.desc {
        margin-top: 30px;
        font-size: 25px;
        letter-spacing: 1px; }
  .artists-team .artist-item {
    width: calc(100% / 4 - 5px); }
  .popup-wraper {
    width: 90%;
    top: 40px;
    transform: translate(-50%, 0); }
  .popup input, .popup textarea {
    position: relative; }
  .popup label, .popup input, .popup textarea {
    display: block;
    font-size: 16px;
    max-width: 100%; }
  .popup .standart-btn {
    width: 100%; }
  .reviews .team-item .avatar-block {
    margin-bottom: 30px; } }

@media screen and (min-width: 993px) and (max-width: 1200px) {
  .services {
    background-position-y: 20px;
    background-size: 90% auto; }
    .services .services-block {
      padding-top: 0; }
      .services .services-block ul li img {
        height: 100px; }
      .services .services-block ul li p.desc {
        margin-top: 30px;
        font-size: 25px; }
  .main-nav ul li {
    margin-right: 25px; }
    .main-nav ul li a {
      font-size: 14px; } }

@media screen and (min-width: 1201px) and (max-width: 1366px) {
  .services {
    background-position-y: 80px;
    background-size: 100% auto; } }

@media screen and (min-width: 1367px) and (max-width: 1600px) {
  .services {
    background-position-y: 80px;
    background-size: 90% auto; } }
