body, html {
    min-width: 320px;
}

.artist-title {
    font-family: $hairline;
    font-size: 30px;
    text-transform: uppercase;
    color: #2b2b2b;
    letter-spacing: 12px;
    margin-top: 55px;
    margin-bottom: 30px;
    div.overflow-container {
        overflow: hidden;
        margin: 0;
        padding: 0;
    }
    h3 {
        margin: 0;
        padding: 0;
        position: relative;
        top: 5px;
        font-weight: 700;
    }
}

#art {
    .artist-title {
        h3 {
            top: 6px;
        }
    }
} 

.content-1 {
    span.text-right {
        display: block;
        width: 100%;
    }
}

.overflow-container {
    overflow: hidden;
    .title-4 {
        display: inline-block;
        margin: 0;
        padding: 0;
        position: relative;
        top: 8px;
        line-height: normal;
    }
}

a, * {
    text-decoration-color: #000000;
}

.text-line {
    text-align: center;
    padding: 100px 0 0 0;
    p {
        font-family: $hairline;
        line-height: 40px;
        font-size: 21px;
    }
}

.and-more {
    font-family: $thin;
    font-size: 14px;
    text-transform: uppercase;
    color: rgba(43,43,43,.55);
    font-weight: 700;
}

.mb {
    margin-bottom: 60px;
}

.shadow {
    font-family: "Lato Hairline",sans-serif;
    font-size: 20px;
    color: #2b2b2b;
    text-transform: uppercase;
    letter-spacing: 15px;
    line-height: normal;
    font-weight: 800;
    position: absolute;
    left: 50%;
    top: 3px;
    opacity: .12;
    white-space: nowrap;
    transform: translate(-50%, 0) scale(1.1);
    max-width: 100%;
    overflow: hidden;
}

.text-line {
    .overflow-container {
        margin: 0 0 100px 0;
        padding: 0;
        p {
            position: relative;
            margin: 0;
            padding: 0;
            top: 13px;
        }
    }
}

body > section.reviews > div > div:nth-child(2) > div:nth-child(1) > div.about-block > div > p:nth-child(2) {
    white-space: nowrap;
}