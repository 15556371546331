.menu-btn {
    display: none;
    width: 40px;
    height: 20px;
    position: relative;
    span {
        position: absolute;
        width: 30px;
        height: 2px;
        background-color: #000;
        left: 5px;
        transform-origin: 50% 50%;
        transition: all .3s ease-in-out;
        &:first-child {
            top: 0;
        }
        &:nth-child(2) {
            top: 50%;
            transform: translate(0, -50%);
            opacity: 1;
        }
        &:last-child {
            top: 100%;
            transform: translate(0, -2px);
        }
    }
    &.active {
        span {
            &:first-child {
                top: 50%;
                transform: translate(0, -50%) rotate(45deg);
            }
            &:nth-child(2) {
                opacity: 0;
            }
            &:last-child {
                top: 50%;
                transform: translate(0, -50%) rotate(135deg);
            }
        }
    }
}

.standart-btn {
    font-family: $hairline;
    display: block;
    padding: 23px;
    max-width: 360px;
    margin: 30px auto 0 auto;
    text-decoration: none !important;
    text-transform: uppercase;
    font-size: 20px;
    color: #5d5d5d;
    border: 2px solid #5d5d5d;
    text-align: center;
    font-weight: bold;
    transition: all .3s ease-in-out;
    outline: none;
    background: none;
    &:hover {
        text-decoration: none;
        color: #fff;
        background-color: #000000;
    }
}

button.standart-btn {
    width: 260px;
    max-width: 260px;
    border: 1px solid #5d5d5d;
}