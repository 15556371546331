// classes system
$size: 195px;
$gutter: 4px;

$mobile-size: $size / 3.64;
$mobile-size-2: $size / 2.264;
$mobile-size-3: $size / 1.394;

.mt {
    margin-top: $gutter !important;
}

.grid-title {
    margin-bottom: 30px;
    .overflow-container {
        margin: 0 0 100px 0;
        padding: 0;
    }
    &--big {
        margin-top: 50px;
        .font-1 {
            font-size: 30px;
            position: relative;
            top: 19px;
        }
        .shadow {
            font-size: 70px;
            top: -15px;
            letter-spacing: 60px;
        }
    }
}

.grid-container {
    display: inline-block;
    margin-top: -$gutter * 2;
    width: 100%;
    max-width: 995px;
}

.g-box {
    background-color: #eee;
    float: left;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    position: relative;
    z-index: 200;
    width: 100%;
    &:hover {
        .bg-col {
            opacity: 1;
        }
    }
    .bg-col {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 300;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        opacity: 0;
        transition: all .2s ease-in;
    }
    .caption {
        position: absolute;
        z-index: 400;
        padding: 15px;
        font-family: $thin;
        font-size: 17px;
        line-height: 19px;
        font-weight: 900;
        text-shadow: .5px 0 0px #2b2b2b; 
        &.top {
            top: 0;
        }
        &.left {
            left: 0;
        }
        &.right {
            right: 0;
        }
        &.bottom {
            bottom: 0;
        }        
        &.dark {
            color: #2b2b2b;
        }
        &.white {
            color: #fff;
        }
    }
}

.g-box-wraper {
    float: left;
    margin: 0 $gutter / 2;
    width: 100%;
    .g-box {
        float: none;
        margin: $gutter 0;
    }
    .g-box-wraper {
        margin: -$gutter $gutter / 2 0 0;
        &:last-child {
            margin-right: 0;
        }
    }
}

.text-uppercase {
    font-variant: small-caps;
}

.w-1 {
    width: $size * 1;
}

.w-2 {
    width: $size * 2 + $gutter;
}

.w-3 {
    width: $size * 3 + $gutter * 2;
}

.w-4 {
    width: $size * 4 + $gutter * 2;
}

.w-33 {
    width: calc(33.33% - 4px);
}

.w-50 {
    width: calc(50% - 4px);
}

.w-100 {
    width: 100%;
}

.h-1 {
    height: $size * 1;
}

.h-11 {
    height: $size * 1.1 + $gutter / 2;
}

.h-12 {
    height: $size * 1.2 + $gutter / 2;
}

.h-13 {
    height: $size * 1.3 + $gutter / 2;
}

.h-14 {
    height: $size * 1.4 + $gutter / 2;
}

.h-15 {
    height: $size * 1.5 + $gutter / 2;
}

.h-16 {
    height: $size * 1.6 + $gutter / 2;
}

.h-17 {
    height: $size * 1.7 + $gutter / 2;
}

.h-18 {
    height: $size * 1.8 + $gutter / 2;
}

.h-19 {
    height: $size * 1.9 + $gutter / 2;
}

.h-2 {
    height: $size * 2 + $gutter;
}

.h-3 {
    height: $size * 3 + $gutter * 2;
}

.h-offset-1 {
    margin-left: $size + $gutter !important;
}

@media screen and (max-width: 480px) {
    .w-1 {
        width: $mobile-size * 1;
    }
    
    .w-2 {
        width: $mobile-size * 2 + $gutter;
    }
    
    .w-3 {
        width: $mobile-size * 3 + $gutter * 2;
    }
    
    .w-4 {
        width: $mobile-size * 4 + $gutter * 2;
    }
    
    .h-1 {
        height: $mobile-size * 1;
    }
    
    .h-11 {
        height: $mobile-size * 1.1 + $gutter / 2;
    }
    
    .h-12 {
        height: $mobile-size * 1.2 + $gutter / 2;
    }
    
    .h-13 {
        height: $mobile-size * 1.3 + $gutter / 2;
    }
    
    .h-14 {
        height: $mobile-size * 1.4 + $gutter / 2;
    }
    
    .h-15 {
        height: $mobile-size * 1.5 + $gutter / 2;
    }
    
    .h-16 {
        height: $mobile-size * 1.6 + $gutter / 2;
    }
    
    .h-17 {
        height: $mobile-size * 1.7 + $gutter / 2;
    }
    
    .h-18 {
        height: $mobile-size * 1.8 + $gutter / 2;
    }
    
    .h-19 {
        height: $mobile-size * 1.9 + $gutter / 2;
    }    
    
    .h-2 {
        height: $mobile-size * 2 + $gutter;
    }
    
    .h-3 {
        height: $mobile-size * 3 + $gutter * 2;
    }
    
    .h-offset-1 {
        margin-left: $mobile-size + $gutter !important;
    }

    .g-box {
        .caption {
            padding: 5px;
            font-size: 12px;
            line-height: 12px;
        }
    }

    .grid-container {
        max-width: 290px;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .w-1 {
        width: $mobile-size-2 * 1;
    }
    
    .w-2 {
        width: $mobile-size-2 * 2 + $gutter;
    }
    
    .w-3 {
        width: $mobile-size-2 * 3 + $gutter * 2;
    }
    
    .w-4 {
        width: $mobile-size-2 * 4 + $gutter * 2;
    }
    
    .h-1 {
        height: $mobile-size-2 * 1;
    }
    
    .h-11 {
        height: $mobile-size-2 * 1.1 + $gutter / 2;
    }
    
    .h-12 {
        height: $mobile-size-2 * 1.2 + $gutter / 2;
    }
    
    .h-13 {
        height: $mobile-size-2 * 1.3 + $gutter / 2;
    }
    
    .h-14 {
        height: $mobile-size-2 * 1.4 + $gutter / 2;
    }
    
    .h-15 {
        height: $mobile-size-2 * 1.5 + $gutter / 2;
    }
    
    .h-16 {
        height: $mobile-size-2 * 1.6 + $gutter / 2;
    }
    
    .h-17 {
        height: $mobile-size-2 * 1.7 + $gutter / 2;
    }
    
    .h-18 {
        height: $mobile-size-2 * 1.8 + $gutter / 2;
    }
    
    .h-19 {
        height: $mobile-size-2 * 1.9 + $gutter / 2;
    }    
    
    .h-2 {
        height: $mobile-size-2 * 2 + $gutter;
    }
    
    .h-3 {
        height: $mobile-size-2 * 3 + $gutter * 2;
    }
    
    .h-offset-1 {
        margin-left: $mobile-size-2 + $gutter !important;
    }

    .g-box {
        .caption {
            padding: 10px;
            font-size: 14px;
            line-height: 14px;
        }
    }
    .grid-container {
        max-width: 451px;
    } 
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .w-1 {
        width: $mobile-size-3 * 1;
    }
    
    .w-2 {
        width: $mobile-size-3 * 2 + $gutter;
    }
    
    .w-3 {
        width: $mobile-size-3 * 3 + $gutter * 2;
    }
    
    .w-4 {
        width: $mobile-size-3 * 4 + $gutter * 2;
    }
    
    .h-1 {
        height: $mobile-size-3 * 1;
    }
    
    .h-11 {
        height: $mobile-size-3 * 1.1 + $gutter / 2;
    }
    
    .h-12 {
        height: $mobile-size-3 * 1.2 + $gutter / 2;
    }
    
    .h-13 {
        height: $mobile-size-3 * 1.3 + $gutter / 2;
    }
    
    .h-14 {
        height: $mobile-size-3 * 1.4 + $gutter / 2;
    }
    
    .h-15 {
        height: $mobile-size-3 * 1.5 + $gutter / 2;
    }
    
    .h-16 {
        height: $mobile-size-3 * 1.6 + $gutter / 2;
    }
    
    .h-17 {
        height: $mobile-size-3 * 1.7 + $gutter / 2;
    }
    
    .h-18 {
        height: $mobile-size-3 * 1.8 + $gutter / 2;
    }
    
    .h-19 {
        height: $mobile-size-3 * 1.9 + $gutter / 2;
    }    
    
    .h-2 {
        height: $mobile-size-3 * 2 + $gutter;
    }
    
    .h-3 {
        height: $mobile-size-3 * 3 + $gutter * 2;
    }
    
    .h-offset-1 {
        margin-left: $mobile-size-3 + $gutter !important;
    }

    .g-box {
        .caption {
            padding: 10px;
        }
    }
    .grid-container {
        max-width: 720px;
    } 
}