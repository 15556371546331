$thin: 'Lato Thin', sans-serif;
$hairline: 'Lato Hairline', sans-serif;

.font-1 {
    font-family: $hairline;
    font-size: 20px;
    color: #2b2b2b;
    text-transform: uppercase;
    letter-spacing: 10px;
    line-height: normal;
    font-weight: bold;
}

.font-3 {
    font-family: $thin;
    font-size: 22px;
    color: #2b2b2b;
    letter-spacing: .1px;
    line-height: 12px;
    font-weight: bold;
    margin-top: 50px;
}

.font-5 {
    font-family: $hairline;
    font-size: 28px;
    color: #2b2b2b;
    letter-spacing: .1px;
    line-height: 26px;
    font-weight: bold;
    margin: 0 0 5px 0;
    text-transform: uppercase;
    &_small {
        font-size: 20px;
        text-transform: none;
    }
}

.link-1 {
    font-family: $thin;
    font-size: 15px;
    color: #2b2b2b;
    text-transform: uppercase;
    letter-spacing: .5px;
    line-height: 10px;
    text-decoration: none;
    font-weight: bold;
    &:hover {
        text-decoration: none;
        color: #2b2b2b;
    }
}

.link-2 {
    display: block;
    font-family: $thin;
    font-size: 20px;
    color: #2b2b2b;
    text-transform: uppercase;
    letter-spacing: .5px;
    line-height: normal;
    text-decoration: none;
    font-weight: bold;
    margin-top: 25px;
    padding-top: 20px;
    &:hover {
        text-decoration: none;
        color: #2b2b2b;
        &:before {
            width: 100%;
        }
    }
    position: relative;
    &:before {
        content: '';
        display: bclock;
        width: 40%;
        height: 2px;
        background-color: #000000;
        transition: all .3s ease-in-out;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.font-2 {
    font-family: $hairline;
    font-size: 30px;
    color: #2b2b2b;
    line-height: normal;
    font-weight: bold;
}

.title-1 {
    font-family: $hairline;
    font-size: 33px;
    color: #2b2b2b;
    line-height: normal;
    font-weight: bold;
    margin-bottom: 37px;
}

.title-2 {
    font-family: $hairline;
    font-size: 18px;
    color: #000000;
    line-height: normal;
    font-weight: bold;
    margin-bottom: 50px;
}

.title-3 {
    font-family: $hairline;
    font-size: 24px;
    color: #2b2b2b;
    line-height: normal;
    font-weight: bold;
    margin-bottom: 40px;
}

.title-4 {
    font-family: $hairline;
    font-size: 35px;
    color: #2b2b2b;
    line-height: 65px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 12px;
    margin-top: 114px;
    margin-bottom: 20px;
    &__subtitle {
        font-size: 22px;
    }
    &__bigger {
        font-size: 50px;
    }
    &.about-title {
        margin-top: 30px;
    }
}

.content-1 {
    font-family: $hairline;
    font-size: 25px;
    color: #2b2b2b;
    line-height: normal;
    font-weight: bold;
}

.content-2 {
    font-family: $hairline;
    font-size: 14px;
    color: #2b2b2b;
    line-height: normal;
    font-weight: bold;
    margin: 0 auto;
    max-width: 215px;
}