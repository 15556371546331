.main-nav {
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        position: relative;
        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 6px;
            background: linear-gradient(to top, rgba(0,0,0,.05) 20%, rgba(255,255,255,.05) 70%);
            background: -moz-linear-gradient(to top, rgba(0,0,0,.05) 20%, rgba(255,255,255,.05) 70%);
            background: -o-linear-gradient(to top, rgba(0,0,0,.05) 20%, rgba(255,255,255,.05) 70%);
            background: -webkit-linear-gradient(to top, rgba(0,0,0,.05) 20%, rgba(255,255,255,.05) 70%);
            position: absolute;
            bottom: 0;
            left: 0;
        }
        li {
            display: inline-block;
            margin-right: 50px;
            position: relative;
            top: 7px;
            &:last-child {
                margin-right: 0;
            }
            a {
                font-family: $thin;
                font-size: 20px;
                color: #2b2b2b;
                text-transform: uppercase;
                text-decoration: none;
            }
        }
    }
}

.socials-nav {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        display: inline-block;
        margin-right: 37px;
        &:last-child {
            margin-right: 0;
        }
        a {
            img {
                width: 80px;
                transition: all .5s ease-in-out;
                transform-origin: 50% 50%;
            }
            // &:hover {
            //     img {
            //         transform: rotate(360deg) scale(1.2);
            //     }
            // }
        }
    }
}